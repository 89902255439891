<template>
  <el-card id="workorder">
    <header>
      <h2 style="display: flex; align-items: center">
        工单管理
        <span v-if="showTitleText">
          <span v-if="roleName == '人员'"
            >(员工可在此提交（请假、外出、报销、出差、退费）工单，管理员/组长可以进行审核)</span
          >
          <span v-else
            >(可在此发起工单、或者查看已审批工单记录。抄送记录 )</span
          >
        </span>
        <el-tooltip
          class="item"
          effect="dark"
          content="点击查看详情说明"
          placement="top"
        >
          <img
            v-if="!showTitleText"
            src="@/assets/images/what.png"
            alt=""
            style="width: 30px; height: 30px; cursor: pointer"
            @click="showTitleText = true"
          />
        </el-tooltip>
      </h2>
    </header>
    <!-- <hr> -->
    <hr />
    <main>
      <el-tabs v-model="activeName">
        <el-tab-pane name="wfqdgd">
          <span slot="label"
            >我发起的工单
            <i
              class="el-icon-circle-plus"
              @click="addOrderBtn"
              style="font-size: 18px"
            ></i
          ></span>
          <initiateWorkorder />
        </el-tab-pane>
        <el-tab-pane label="我审批的工单" name="wspdgd">
          <yetCheckWorkorder />
        </el-tab-pane>
        <el-tab-pane label="抄送历史记录" name="cslsjl">
          <history />
        </el-tab-pane>
      </el-tabs>
    </main>
    <!-- 新增工单弹出框 -->
    <el-dialog title="新增工单" :visible.sync="addOrderVisible">
      <el-tabs v-model="activeNameAdd" @tab-click="handleClick">
        <el-tab-pane label="请假" name="qj">
          <el-form
            ref="addForm"
            :model="addForm"
            class="audit"
            label-width="100px"
          >
            <el-form-item label="请假类型" prop="askType">
              <el-select
                v-model="addForm.askType"
                placeholder="请选择"
                filterable
              >
                <el-option
                  v-for="item in QJaskTypeList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.title"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="请假时间" prop="workorderTime">
              <el-date-picker
                v-model="addForm.workorderTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="请假事由" prop="workMessage">
              <el-input
                v-model="addForm.workMessage"
                placeholder="请输入请假事由"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item label="附件" prop="workImg">
              <el-upload
                :limit="3"
                class="upload-demo"
                :headers="uploadHeaders"
                :multiple="true"
                action="api/workOrder/upload"
                :file-list="addForm.workImg"
                :on-exceed="handleExceed"
                :before-remove="beforeRemove"
                :on-success="handleAvatarSuccess"
                list-type="picture"
              >
                <el-button size="mini" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="addOrderSubmit" style="width: 100%"
            >确定</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="外出" name="wc">
          <el-form
            ref="addForm"
            :model="addForm"
            class="audit"
            label-width="100px"
          >
            <el-form-item label="外出地点" prop="askType">
              <el-input
                v-model="addForm.askType"
                placeholder="请输入外出地点"
              ></el-input>
            </el-form-item>
            <el-form-item label="外出时间" prop="workorderTime">
              <el-date-picker
                v-model="addForm.workorderTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="外出事由" prop="workMessage">
              <el-input
                v-model="addForm.workMessage"
                placeholder="请输入外出事由"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item label="附件" prop="workImg">
              <el-upload
                :limit="3"
                class="upload-demo"
                :headers="uploadHeaders"
                :multiple="true"
                action="api/workOrder/upload"
                :file-list="addForm.workImg"
                :on-exceed="handleExceed"
                :before-remove="beforeRemove"
                :on-success="handleAvatarSuccess"
                list-type="picture"
              >
                <el-button size="mini" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="addOrderSubmit" style="width: 100%"
            >确定</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="报销" name="bx">
          <el-form
            ref="addForm"
            :model="addForm"
            class="audit"
            label-width="100px"
          >
            <el-form-item label="报销类型" prop="askType">
              <el-select
                v-model="addForm.askType"
                placeholder="请选择"
                filterable
              >
                <el-option
                  v-for="item in BXaskTypeList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.title"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发生时间" prop="endTime">
              <el-date-picker
                align="center"
                v-model="addForm.endTime"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="费用金额" prop="askTime">
              <el-input
                v-model="addForm.askTime"
                placeholder="请输入费用金额"
                style="width: 220px"
              ></el-input>
            </el-form-item>
            <el-form-item label="费用类型" prop="beginTime">
              <el-select
                v-model="addForm.beginTime"
                placeholder="请选择"
                filterable
              >
                <el-option
                  v-for="item in BXFYaskTypeList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.title"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报销事由" prop="BXworkMessage">
              <el-input
                v-model="addForm.BXworkMessage"
                placeholder="请输入报销事由"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item label="费用说明" prop="remark">
              <el-input
                v-model="addForm.remark"
                placeholder="请输入费用说明"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item label="附件" prop="workImg">
              <el-upload
                :limit="3"
                class="upload-demo"
                :headers="uploadHeaders"
                :multiple="true"
                action="api/workOrder/upload"
                :file-list="addForm.workImg"
                :on-exceed="handleExceed"
                :before-remove="beforeRemove"
                :on-success="handleAvatarSuccess"
                list-type="picture"
              >
                <el-button size="mini" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="addOrderSubmit" style="width: 100%"
            >确定</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="出差" name="cc">
          <el-form
            ref="addForm"
            :model="addForm"
            class="audit"
            label-width="100px"
          >
            <el-form-item label="出差地点" prop="remark">
              <el-input
                v-model="addForm.remark"
                placeholder="请输入出差地点"
              ></el-input>
            </el-form-item>
            <el-form-item label="出差时间" prop="workorderTime">
              <el-date-picker
                v-model="addForm.workorderTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="出差事由" prop="workMessage">
              <el-input
                v-model="addForm.workMessage"
                placeholder="请输入出差事由"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item label="附件" prop="workImg">
              <el-upload
                :limit="3"
                class="upload-demo"
                :headers="uploadHeaders"
                :multiple="true"
                action="api/workOrder/upload"
                :file-list="addForm.workImg"
                :on-exceed="handleExceed"
                :before-remove="beforeRemove"
                :on-success="handleAvatarSuccess"
                list-type="picture"
              >
                <el-button size="mini" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="addOrderSubmit" style="width: 100%"
            >确定</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="其它" name="qt">
          <el-form :model="addForm" class="audit" label-width="100px">
            <el-form-item label="工单类型" prop="workOrderType">
              <el-input
                v-model="addForm.workOrderType"
                placeholder="请输入工单类型"
                style="width: 220px"
              ></el-input>
            </el-form-item>
            <el-form-item label="工单内容" prop="workMessage">
              <el-input
                v-model="addForm.workMessage"
                placeholder="请输入工单内容"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item label="附件" prop="workImg">
              <el-upload
                :limit="3"
                class="upload-demo"
                :headers="uploadHeaders"
                :multiple="true"
                action="api/workOrder/upload"
                :file-list="addForm.workImg"
                :on-exceed="handleExceed"
                :before-remove="beforeRemove"
                :on-success="handleAvatarSuccess"
                list-type="picture"
              >
                <el-button size="mini" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <!-- https://sys.cddmt.cn/api/workOrder/upload -->
            <!-- api/workOrder/upload" -->
          </el-form>
          <el-button
            type="primary"
            @click="QTaddOrderSubmit"
            style="width: 100%"
            >确定</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="内部工单" name="nb" v-if="getIdBool">
          <el-form :model="addForm" class="audit" label-width="100px">
            <el-form-item label="工单类型" prop="workOrderType">
              <el-select
                v-model="addForm.workOrderType"
                placeholder="请选择工单类型"
                filterable
              >
                <el-option
                  v-for="item in QJaskTypeListInternal"
                  :key="item.id"
                  :label="item.title"
                  :value="item.title"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工单内容" prop="workMessage">
              <el-input
                v-model="addForm.workMessage"
                placeholder="请输入工单内容"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item label="附件" prop="workImg">
              <el-upload
                :limit="3"
                class="upload-demo"
                :headers="uploadHeaders"
                :multiple="true"
                action="api/workOrder/upload"
                :file-list="addForm.workImg"
                :on-exceed="handleExceed"
                :before-remove="beforeRemove"
                :on-success="handleAvatarSuccess"
                list-type="picture"
              >
                <el-button size="mini" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            @click="QTaddOrderSubmit"
            style="width: 100%"
            >确定</el-button
          >
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import initiateWorkorder from "@/views/workorder/modules/initiateWorkorder.vue";
import yetCheckWorkorder from "@/views/workorder/modules/yetCheckWorkorder.vue";
import history from "@/views/workorder/history.vue";
import { workOrderAddWorkOrderType } from "@/api/workOrder";
import { approverAdd } from "@/api/approver";
import { workOrderAddWorkUserOrderType } from "@/api/workOrder";
export default {
  components: { initiateWorkorder, yetCheckWorkorder, history },
  data() {
    return {
      showTitleText: false,
      uploadHeaders: {
        "X-Access-Token": localStorage.getItem("TOKEN"),
      }, // 请求头携带token值
      roleName: null,
      addForm: {
        imgUrl: "",
        remark1: null,
        remark2: null,
        remark3: null,
        remark4: null,
        BXworkMessage: null,
        endTime: null,
        askTime: null,
        beginTime: null,
        remark: null,
        workOrderType: null,
        askType: null,
        workorderTime: null,
        workMessage: null,
        workImg: [],
      },
      QJaskTypeList: [
        {
          id: 0,
          title: "事假",
        },
        {
          id: 1,
          title: "病假",
        },
        {
          id: 2,
          title: "调休假",
        },
        {
          id: 3,
          title: "婚假",
        },
        {
          id: 4,
          title: "产假",
        },
        {
          id: 5,
          title: "陪产假",
        },
        {
          id: 6,
          title: "年假",
        },
        {
          id: 7,
          title: "其它",
        },
      ],
      QJaskTypeListInternal: [
      // 8216-康容   李总-8030   罗亚萍(财务)-8029  沈凤玲-25244   刘总-5842
        {
          id: 0,
          title: "探客返现",
          auditUserId: [24282, 5842, 8029],
          copyUserId: [],
        },
        {
          id: 1,
          title: "组长账户",
          auditUserId: [5842, 8066],
          copyUserId: [],
        },
        {
          id: 2,
          title: "转移费用",
          auditUserId: [8216, 8029],
          copyUserId: [],
        },
        {
          id: 3,
          title: "预充值",
          auditUserId: [8030,8029,8216,25244],
          copyUserId: [],
        },
        {
          id: 4,
          title: "变更套餐",
          auditUserId: [24282, 5842, 8216, 8029],
          copyUserId: [],
        },
        {
          id: 5,
          title: "利润结算",
          auditUserId: [8030, 8216, 8029],
          copyUserId: [],
        },
        {
          id: 6,
          title: "退费",
          // auditUserId: [24282, 5842, 8216, 8030, 8029],
          auditUserId: [24282, 5842, 8216, 8029, 8030, 25244],
          copyUserId: [],
        },
        {
          id: 7,
          title: "预增加坐席额度",
          auditUserId: [5842, 8216, 8029],
          copyUserId: [],
        },
        {
          id: 8,
          title: "老客户复开余额返回",
          auditUserId: [24282, 8030, 8216, 8029],
          copyUserId: [],
        },
        {
          id: 9,
          title: "直客返费",
          auditUserId: [24282, 5842, 8216, 8029],
          copyUserId: [],
        },
        {
          id: 10,
          title: "渠道返费",
          auditUserId: [8030, 8216, 8029],
          copyUserId: [],
        },
        {
          id: 11,
          title: "拨打时间调整",
          auditUserId: [5842, 8216],
          copyUserId: [],
        },
        {
          id: 12,
          title: "拓客宝测试(赠送)",
          auditUserId: [5842, 8216, 8029],
          copyUserId: [],
        },
        {
          id: 13,
          title: "开通号码检测",
          auditUserId: [8216],
          copyUserId: [],
        }
      ],
      BXaskTypeList: [
        {
          id: 0,
          title: "差旅费",
        },
        {
          id: 1,
          title: "交通费",
        },
        {
          id: 2,
          title: "招待费",
        },
        {
          id: 3,
          title: "其它",
        },
      ],
      BXFYaskTypeList: [
        {
          id: 0,
          title: "飞机票",
        },
        {
          id: 1,
          title: "火车票",
        },
        {
          id: 2,
          title: "的士费",
        },
        {
          id: 3,
          title: "住宿费",
        },
        {
          id: 4,
          title: "餐饮费",
        },
        {
          id: 5,
          title: "礼品费",
        },
        {
          id: 6,
          title: "活动费",
        },
        {
          id: 7,
          title: "通讯费",
        },
        {
          id: 8,
          title: "补助",
        },
        {
          id: 9,
          title: "其它",
        },
      ],
      TFaskTypeList: [
        {
          id: 0,
          title: "开发票",
        },
        {
          id: 1,
          title: "不开发票",
        },
      ],
      activeNameAdd: "qj",
      addOrderVisible: false,
      activeName: "wfqdgd",
      parentId: null,
      userId: null,
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.parentId = localStorage.getItem("parentId");
    this.userId = localStorage.getItem("id");
  },
  computed: {
    getIdBool() {
      if (this.parentId == 7) {
        return true;
      } else if (this.parentId && this.userId == 7) {
        return true;
      } else {
        return false;
      }
    },
  },
  inject: ["reload"],
  methods: {
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 3 个文件,最多只能上传3个文件,超过上限则上传无效`
      );
    },
    // 图片上传成功时
    handleAvatarSuccess(res, file, fileList) {
      this.imgUrl = "";
      if (fileList) {
        this.imgUrl = fileList.map((v) => {
          return v.response;
        });
      }
      this.imgUrl ? (this.imgUrl = this.imgUrl.join()) : "";

      // let imageUrl = URL.createObjectURL(file.raw);
      // console.log("res", res);
      // console.log("file", file);
      // console.log("addForm.workImg", this.imgUrl);

      // console.log("imageUrl", imageUrl);
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 新增工单确定--其它
    async QTaddOrderSubmit() {
      let params = {
        workOrderType: this.addForm.workOrderType,
        workMessage: this.addForm.workMessage,
        workImg: this.imgUrl,
      };
      let res = await workOrderAddWorkOrderType(params);
      Message.success(res.data);
      this.addOrderVisible = false;
      if (res.data.data) {
        await this.getInternalOrder(this.addForm.workOrderType, res.data.data);
      }
      this.reload();
    },
    // 判断是否是内部工单类型
    getInternalOrder(type, id) {
      this.QJaskTypeListInternal.forEach(async (v) => {
        if (v.title == type) {
          console.log("预充值工单",v);
          let params = {
            workOrderTypeId: id,
            auditUserId: v.auditUserId,
            copyUserId: v.copyUserId,
          };
          let res = await workOrderAddWorkUserOrderType(params);
          // Message.success(res.data);
        }
      });
    },
    // tabs切换
    handleClick(e) {
      this.cutIndex = e.index;
    },
    // 新增工单确定
    addOrderSubmit() {
      if (this.cutIndex != 2) {
        this.beginTime = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.addForm.workorderTime[0]
        );
        this.endTime = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.addForm.workorderTime[1]
        );
      }
      if (this.cutIndex == 0 || !this.cutIndex) {
        this.params = {
          workOrderType: 1,
          askType: this.addForm.askType,
          beginTime: this.beginTime,
          endTime: this.endTime,
          workMessage: this.addForm.workMessage,
          workImg: this.imgUrl,
        };
      } else if (this.cutIndex == 1) {
        this.params = {
          workOrderType: 2,
          askType: this.addForm.askType,
          beginTime: this.beginTime,
          endTime: this.endTime,
          workMessage: this.addForm.workMessage,
          workImg: this.imgUrl,
        };
      } else if (this.cutIndex == 2) {
        this.params = {
          workOrderType: 3,
          askType: this.addForm.askType,
          endTime: this.addForm.endTime,
          askTime: this.addForm.askTime,
          beginTime: this.addForm.beginTime,
          workMessage: this.addForm.BXworkMessage,
          remark: this.addForm.remark,
          workImg: this.imgUrl,
        };
      } else if (this.cutIndex == 3) {
        this.params = {
          workOrderType: 4,
          remark: this.addForm.remark,
          beginTime: this.beginTime,
          endTime: this.endTime,
          workImg: this.imgUrl,
          workMessage: this.addForm.workMessage,
        };
      }
      let res = approverAdd(this.params);
      res.then((res) => {
        // Message.success("该功能暂未开放,请在‘其它’选项中发起工单");
        Message.success(res.data);
        this.addOrderVisible = false;
        this.reload();
      });
    },
    // 新增工单
    addOrderBtn() {
      this.addOrderVisible = true;
      this.addForm = [];
    },
  },
};
</script>

<style lang="less" scoped>
#workorder {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;

      > span {
        font-size: 18px;
        color: #606369;
      }

      > div {
        font-size: 18px;
        color: red;
        margin-top: 20px;
      }
    }
  }

  main {
  }
}
</style>