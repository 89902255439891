<template>
  <el-card id="yetCheckWorkorder">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="请假" name="qj">
        <qj ref="qj" />
      </el-tab-pane>
      <el-tab-pane label="外出" name="wc">
        <wc ref="wc" />
      </el-tab-pane>
      <el-tab-pane label="报销" name="bx">
        <bx ref="bx" />
      </el-tab-pane>
      <el-tab-pane label="出差" name="cc">
        <cc ref="cc" />
      </el-tab-pane>
      <!-- <el-tab-pane label="退费" name="tf">
        <tf />
      </el-tab-pane> -->
      <el-tab-pane label="其它" name="qt">
        <qt ref="qt" />
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import qj from "@/views/workorder/modules/yet/qj.vue";
import wc from "@/views/workorder/modules/yet/wc.vue";
import bx from "@/views/workorder/modules/yet/bx.vue";
import cc from "@/views/workorder/modules/yet/cc.vue";
import tf from "@/views/workorder/modules/yet/tf.vue";
import qt from "@/views/workorder/modules/yet/qt.vue";

export default {
  components: { qj, wc, bx, cc, tf, qt },
  data() {
    return {
      activeName: "qj",
      parentId: null,
    };
  },
  created() {
    this.parentId = localStorage.getItem("parentId");
    this.$nextTick(()=>{
      this.$refs.qj.getList()
    })
  },
  methods: {
    handleClick(tab, event) {
      // console.log('66666666',tab.paneName, event);
      this.$refs[tab.paneName].getList()
    }
  }
};
</script>

<style lang="less" scoped>
#yetCheckWorkorder {
  /deep/.el-dialog {
    width: 70%;
  }
}
</style>