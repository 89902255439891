<template>
  <el-card id="history">
    <header>
      <!-- <h1>抄送历史记录</h1> -->
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <!-- 多选按钮 -->
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="申请人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="workOrderType"
          label="申请类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="workMessage"
          label="工单内容"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="endDate"
          label="结束时间"
          align="center"
        ></el-table-column>
        <el-table-column prop="workImg" label="附件地址" align="center">
          <template v-slot="scope">
             <el-link v-for="(v, i) in  getImgArr(scope.row.workImg)" type="success" :href="v"
              style="margin-left: 10px !important;" target="_blank"
              v-if="scope.row.workImg != null && scope.row.workImg != ''">{{
                getImgArr(scope.row.workImg).length == 0 ? '无附件' : '附件' + (i + 1) }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template v-slot="scope">
            <div :class="fontClass(scope.row.status)">
              {{ statusClass(scope.row.status) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template #default="scope">
            <el-button type="primary" @click="planBtn(scope)" size="mini"
              >审批进度</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 审批进度 -->
    <el-dialog title="审批进度" :visible.sync="planVisible">
      <el-divider content-position="center" style="margin-bottom: 40px"
        >审批人</el-divider
      >
      <el-timeline>
        <el-timeline-item
          v-for="v in planData"
          :key="v.id"
          :icon="iconPlan(v.status)"
        >
          <div :class="planClass(v.status)" class="plan-status">
            <h4>{{ v.orderTypeName }}</h4>
            <p>{{ planStatus(v.status) }}</p>
            <p>{{ v.approverDate }}</p>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="v.auditRemark"
              disabled
            >
            </el-input>
          </div>
        </el-timeline-item>
      </el-timeline>
      <el-divider content-position="center" style="margin-top: 50px"
        >抄送人</el-divider
      >
      <div class="csr">
        <div v-for="v in planCsrData" :key="v.id" style="text-align: center">
          <img
            src="../../assets/images/yonghu.png"
            alt=""
            style="width: 30px; height: 30px"
          />
          <div style="font-size: 12px">{{ v.copyName }}</div>
        </div>
      </div>
      <!-- <template #footer>
        <span class="dialog-footer">
          <el-button @click="planVisible = false">取消</el-button>
          <el-button type="primary" @click="planSubmit()">确定</el-button>
        </span>
      </template> -->
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  workOrderUpdateWorkUserOrderType,
  workOrderSelectWorkUserOrderType,
  workOrderSelectWorkOrderTypeCopy,
  workOrderCopySee,
} from "@/api/workOrder";
export default {
  data() {
    return {
      auditRemark: null,
      workMessage: null,
      workOrderTypeIda: null,
      radio: null,
      options: [
        {
          value: 0,
          label: "待审批",
        },
        {
          value: 1,
          label: "审核中",
        },
        {
          value: 2,
          label: "审批未通过",
        },
        {
          value: 3,
          label: "审批通过",
        },
      ],
      planForm: {},
      copyName: "无",
      planData: [],
      planCsrData: [],
      planVisible: false,
      // 树形控件的参数
      props: {
        label: "label",
        children: "zones",
      },
      data: [], // 选择审批人
      //  ---------
      updataForm: {},
      orderUpdateVisible: false,
      forms: {},
      orderVisible: false,
      addForm: {},
      userName: null,
      endDate: null,
      workOrderType: null,
      createDate: null,
      status: null,
      addOrderVisible: false,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  async created() {
    this.parentId = localStorage.getItem("parentId");
    this.roleName = localStorage.getItem("roleName");
    this.getId = localStorage.getItem("id");
    await this.getList();
  },
  methods: {
    getImgArr(str) {
       
      if (!str) {
        return []
      } else {
        return str.split(',')
      }
    },
    // // 查找
    // handleSearch() {
    //   this.page = 1;
    //   this.getList();
    // },
    // 重置
    reset() {
      this.userName = null;
      this.status = null;
      this.getList();
    },
    // 审批修改状态确定
    planSubmit() {
      console.log("当前审批人id", this.forms);
      let params = {
        workOrderTypeId: this.workOrderTypeIda,
        tableId: this.tableId,
        status: this.radio,
      };
      let res = workOrderUpdateWorkUserOrderType(params);
      res.then((res) => {
        Message.success(res.data);
        this.planVisible = false;
        this.getList();
      });
    },
    // 审批人动态渲染
    planStatus(value) {
      if (value == 1) {
        return "待审批";
      } else if (value == 2) {
        return "审核中";
      } else if (value == 3) {
        return "审批通过";
      } else if (value == 4) {
        return "审批未通过";
      }
    },
    iconPlan(value) {
      if (value == 1) {
        return "el-icon-bottom";
      } else if (value == 2) {
        return "el-icon-bottom";
      } else if (value == 3) {
        return "el-icon-circle-check";
      } else if (value == 4) {
        return "el-icon-circle-close";
      }
    },
    planClass(value) {
      if (value == 1) {
        return "plan-status1";
      } else if (value == 2) {
        return "plan-status2";
      } else if (value == 3) {
        return "plan-status3";
      } else if (value == 4) {
        return "plan-status4";
      }
    },
    // 审批进度
    planBtn(scope) {
      // this.planForm = scope.row;
      this.planVisible = true;
      this.workOrderTypeIda = scope.row.id;
      let params = {
        workOrderTypeId: this.workOrderTypeIda,
      };
      let res = workOrderSelectWorkUserOrderType(params);
      let res1 = workOrderSelectWorkOrderTypeCopy(params);
      res.then((a) => {
        this.planData = a.data.data;
        a.data.data.forEach((v) => {
          // console.log("v.status", v.status);
          if (v.status == 2) {
            this.tableId = v.id;
            this.planId = v.userId;
          }
        });
      });
      res1.then((b) => {
        this.planCsrData = b.data.data;
      });
    },

    // 状态分类
    statusClass(status) {
      if (status == 0) {
        return "未审核";
      } else if (status == 1) {
        return "审核中";
      } else if (status == 2) {
        return "审核未通过";
      } else if (status == 3) {
        return "审核通过";
      }
    },
    // 状态分类字体样式
    fontClass(status) {
      if (status == 0) {
        return "status0";
      } else if (status == 1) {
        return "status1";
      } else if (status == 2) {
        return "status2";
      } else if (status == 3) {
        return "status3";
      }
    },

    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
      };
      let res = workOrderCopySee(params);
      res.then((res) => {
        this.pageTotal = res.data.data.total;
        this.tableData = res.data.data.records;
      });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
#history {
  header {
    h1 {
      font-weight: normal;
    }
  }
  main {
    .status0 {
      color: #909399;
    }
    .status1 {
      color: #e6a23c;
    }
    .status2 {
      color: #f56c6c;
    }
    .status3 {
      color: #67c23a;
    }
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>