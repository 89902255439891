<template>
  <el-card id="initiateWorkorder">
    <header>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>申请人</span>
            <el-input
              v-model="userName"
              placeholder="请输入申请人"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>工单类型</span>
            <el-input
              v-model="workOrderType"
              placeholder="请输入工单类型"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>状态</span>
            <el-select v-model="status" placeholder="请选择状态" size="mini">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <!-- 多选按钮 -->
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="申请人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="workOrderType"
          label="工单类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="workMessage"
          label="工单内容"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
        ></el-table-column
        ><el-table-column prop="workImg" label="附件地址" align="center">
          <template v-slot="scope">
             <el-link v-for="(v, i) in  getImgArr(scope.row.workImg)" type="success" :href="v"
              style="margin-left: 10px !important;" target="_blank"
              v-if="scope.row.workImg != null && scope.row.workImg != ''">{{
                getImgArr(scope.row.workImg).length == 0 ? '无附件' : '附件' + (i + 1) }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template v-slot="scope">
            <div :class="fontClass(scope.row.status)">
              {{ statusClass(scope.row.status) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="450" align="center">
          <template #default="scope">
            <el-button
              type="primary"
              @click="orderUpdate(scope)"
              v-if="scope.row.status == 0"
              size="mini"
              >编辑</el-button
            >
            <el-button
              type="primary"
              @click="orderUpdate(scope)"
              disabled
              v-else
              size="mini"
              >编辑</el-button
            >
            <el-button
              type="primary"
              @click="showSelect(scope)"
              v-if="scope.row.status == 0"
              size="mini"
              >选择审批人</el-button
            >
            <el-button
              type="primary"
              @click="showSelect(scope)"
              disabled
              v-else
              size="mini"
              >选择审批人</el-button
            >
            <el-button type="primary" @click="planBtn(scope)" size="mini"
              >审批进度</el-button
            >
            <el-popconfirm
              confirm-button-text="是"
              cancel-button-text="否"
              class="reply-change1"
              icon-color="#67C23A"
              title="确定发送短信吗?"
              @confirm="noteSend(scope)"
              @cancel="cancelEventNote"
            >
              <template #reference>
                <el-button type="success" size="mini" style="margin-left: 10px"
                  >发送短信</el-button
                >
              </template>
            </el-popconfirm>
            <el-popconfirm
              confirm-button-text="是"
              cancel-button-text="否"
              class="reply-change1"
              icon-color="red"
              title="确定删除该工单吗?"
              @confirm="orderDel(scope)"
              @cancel="cancelEvent"
            >
              <template #reference>
                <el-button
                  type="danger"
                  v-if="scope.row.status == 0"
                  size="mini"
                  style="margin-left: 10px"
                  >删除</el-button
                >
                <el-button
                  type="danger"
                  disabled
                  v-else
                  size="mini"
                  style="margin-left: 10px"
                  >删除</el-button
                >
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 编辑工单弹出框 -->
    <el-dialog title="编辑工单" :visible.sync="orderUpdateVisible" width="30%">
      <el-form :model="updataForm" class="audit" label-width="100px">
        <el-form-item label="工单名称" prop="workOrderType">
          <el-input
            v-model="updataForm.workOrderType"
            placeholder="请输入工单名称"
            style="width: 220px"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请内容" prop="workMessage">
          <el-input
            v-model="updataForm.workMessage"
            placeholder="请输入申请内容"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="附件" prop="workImg">
          <el-upload
            :limit="1"
            class="upload-demo"
            :headers="uploadHeaders"
            action="http://localhost/api/workOrder/upload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="addForm.workImg"
            :on-success="handleAvatarSuccess"
            list-type="picture"
          >
            <el-button size="mini" type="primary">点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </template>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="orderUpdateVisible = false">取消</el-button>
          <el-button type="primary" @click="orderUpdateSubmit">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 审批人&抄送人 -->
    <el-dialog title="选择" :visible.sync="orderVisible" @close="closeDialog">
      <el-divider content-position="left">审批人</el-divider>
      <el-cascader-panel
        :options="options1"
        v-model="sprVal"
        ref="cascaderBox"
      ></el-cascader-panel>
      <div class="spr-selected">
        <el-button
          style="margin-bottom: 10px; position: relative"
          v-for="(item, i) in sprValArr"
          :key="item"
          >{{ item.label
          }}<i
            class="el-icon-circle-close"
            @click="delUser(i)"
            style="color: red; position: absolute; top: -20%; right: -10%"
          ></i
        ></el-button>
      </div>

      <el-divider content-position="left">抄送人</el-divider>
      <el-cascader-panel
        :options="options1"
        v-model="sprVal1"
        ref="cascaderBox1"
      ></el-cascader-panel>
      <div class="spr-selected">
        <el-button
          style="margin-bottom: 10px; position: relative"
          v-for="(item, i) in sprValArr1"
          :key="item"
          >{{ item.label
          }}<i
            class="el-icon-circle-close"
            @click="delUser1(i)"
            style="color: red; position: absolute; top: -20%; right: -10%"
          ></i
        ></el-button>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="orderVisible = false">取消</el-button>
          <el-button type="primary" @click="orderAdd">添加审批人</el-button>
          <el-button type="primary" @click="orderAdd1">添加抄送人</el-button>
          <el-button type="primary" @click="orderSubmit">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 审批进度 -->
    <el-dialog title="详情 & 审批进度" :visible.sync="planVisible">
      <el-descriptions
        title="工单详情信息"
        :column="2"
        border
        v-if="currentRow"
      >
        <el-descriptions-item label="申请人">{{
          currentRow.userName
        }}</el-descriptions-item>
        <el-descriptions-item label="工单类型">{{
          currentRow.workOrderType
        }}</el-descriptions-item>
        <el-descriptions-item label="工单内容">{{
          currentRow.workMessage
        }}</el-descriptions-item>

        <el-descriptions-item label="审核状态"
          ><el-tag size="mini" :type="setType(currentRow.status)">{{
            currentRow.status | setStatus
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="创建时间">{{
          getYMDHMS("YYYY-mm-dd HH:MM:SS", currentRow.createDate)
        }}</el-descriptions-item>

        <el-descriptions-item label="附件">
           <!-- <el-link
            v-if="!getBool(currentRow.workImg)"
            :href="currentRow.workImg"
            target="_blank"
            type="primary"
            >{{ currentRow.workImg || "无附件" }}</el-link
          >
          <el-image
            v-else
            style="width: 100px; height: 100px"
            :src="currentRow.workImg"
            :preview-src-list="[currentRow.workImg]"
          >
          </el-image> -->
          <el-image
            v-for="(v, i) in getImgArr(currentRow.workImg)"
            style="margin-right: 6px; width: 100px; height: 100px"
            :src="v"
            :preview-src-list="getImgArr(currentRow.workImg)"
          >
          </el-image>
        </el-descriptions-item>
      </el-descriptions>

      <el-divider content-position="center" style="margin-bottom: 40px"
        >审批人</el-divider
      >
      <el-timeline>
        <el-timeline-item
          v-for="v in planData"
          :key="v.id"
          :icon="colorPlan(v.status)"
        >
          <div :class="planClass(v.status)" class="plan-status">
            <h4>{{ v.orderTypeName }}</h4>
            <p>{{ planStatus(v.status) }}</p>
            <p>{{ v.approverDate }}</p>
            <div v-if="v.status == 2 && planId == getId">
              <el-radio v-model="radio" label="3">通过</el-radio>
              <el-radio v-model="radio" label="4">驳回</el-radio>
            </div>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="auditRemark"
              v-if="v.status == 2 && planId == getId"
            >
            </el-input>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="v.auditRemark"
              v-else
              disabled
            >
            </el-input>
          </div>
        </el-timeline-item>
      </el-timeline>
      <el-divider content-position="center" style="margin-top: 50px"
        >抄送人</el-divider
      >
      <div class="csr">
        <div v-for="v in planCsrData" :key="v.id" style="text-align: center">
          <img
            src="../../../../assets/images/yonghu.png"
            alt=""
            style="width: 30px; height: 30px"
          />
          <div style="font-size: 12px">{{ v.copyName }}</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="planVisible = false">取消</el-button>
          <el-button type="primary" @click="planSubmit()">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { approverAdd } from "@/api/approver";
import {
  workOrderUpdateWorkUserOrderType,
  workOrderSelectWorkUserOrderType,
  workOrderUpdateWorkOrderType,
  workOrderAddWorkUserOrderType,
  workOrderDeleteWorkOrderType,
  workOrderAddWorkOrderType,
  workOrderLikeSearch,
  workOrderSend,
  workOrderSelectWorkOrderTypeCopy,
  workOrderParticipantWorkOrder,
} from "@/api/workOrder";
import { userList1 } from "@/api/user";
export default {
  data() {
    return {
      currentRow:{},
      QJaskTypeList: [
        {
          id: 0,
          title: "事假",
        },
        {
          id: 1,
          title: "病假",
        },
        {
          id: 2,
          title: "调休假",
        },
        {
          id: 3,
          title: "婚假",
        },
        {
          id: 4,
          title: "产假",
        },
        {
          id: 5,
          title: "陪产假",
        },
        {
          id: 6,
          title: "年假",
        },
        {
          id: 7,
          title: "其它",
        },
      ],
      BXaskTypeList: [
        {
          id: 0,
          title: "差旅费",
        },
        {
          id: 1,
          title: "交通费",
        },
        {
          id: 2,
          title: "招待费",
        },
        {
          id: 3,
          title: "其它",
        },
      ],
      BXFYaskTypeList: [
        {
          id: 0,
          title: "飞机票",
        },
        {
          id: 1,
          title: "火车票",
        },
        {
          id: 2,
          title: "的士费",
        },
        {
          id: 3,
          title: "住宿费",
        },
        {
          id: 4,
          title: "餐饮费",
        },
        {
          id: 5,
          title: "礼品费",
        },
        {
          id: 6,
          title: "活动费",
        },
        {
          id: 7,
          title: "通讯费",
        },
        {
          id: 8,
          title: "补助",
        },
        {
          id: 9,
          title: "其它",
        },
      ],
      TFaskTypeList: [
        {
          id: 0,
          title: "开发票",
        },
        {
          id: 1,
          title: "不开发票",
        },
      ],
      activeName: "qj",
      sprVal1: [],
      auditRemark: null,
      sprValArr: [],
      sprValArr1: [],
      imgUrl: "",
      uploadHeaders: {
        "X-Access-Token": localStorage.getItem("TOKEN"),
      }, // 请求头携带token值
      sprVal: null,
      sprArr: [],
      options1: [],
      disabled: false,
      workOrderType: null,
      workOrderTypeIda: null,
      radio: null,
      options: [
        {
          value: 0,
          label: "待审批",
        },
        {
          value: 1,
          label: "审核中",
        },
        {
          value: 2,
          label: "审批未通过",
        },
        {
          value: 3,
          label: "审批通过",
        },
      ],
      planForm: {},
      copyName: "无",
      planData: [],
      planCsrData: [],
      planVisible: false,
      // 树形控件的参数
      props: {
        label: "label",
        children: "zones",
      },
      data: [], // 选择审批人
      //  ---------
      updataForm: {},
      orderUpdateVisible: false,
      forms: {},
      orderVisible: false,
      addForm: {
        remark1: null,
        remark2: null,
        remark3: null,
        remark4: null,
        BXworkMessage: null,
        endTime: null,
        askTime: null,
        beginTime: null,
        remark: null,
        workOrderType: null,
        askType: null,
        workorderTime: null,
        workMessage: null,
        workImg: [],
      },
      workMessage: null,
      userName: null,
      workOrderType: null,
      createDate: null,
      status: null,
      addOrderVisible: false,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  async created() {
    this.parentId = localStorage.getItem("parentId");
    this.roleName = localStorage.getItem("roleName");
    this.getId = localStorage.getItem("id");
    // await this.getList();
    await this.orderBtn();
  },
  filters: {
    setStatus(data) {
      if (data == 0) {
        return "未审核";
      } else if (data == 1) {
        return "审核中";
      } else if (data == 2) {
        return "审核未通过";
      } else if (data == 3) {
        return "审核已通过";
      }
    },
  },
  methods: {
    getImgArr(str) {
       
      if (!str) {
        return []
      } else {
        return str.split(',')
      }
    },
     // 审核状态tag的type
     setType(data) {
      if (data == 0) {
        return "info";
      } else if (data == 2) {
        return "danger";
      } else if (data == 3) {
        return "success";
      }
    },
    getBool(url) {
      if (url) {
        let hz = url.split(".").slice(-1)[0].toLowerCase();

        // console.log("6666", hz); // 输出txt
        // console.log("6666", this.isAssetTypeAnImage(hz)); // 输出txt

        return this.isAssetTypeAnImage(hz);
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        [
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "gif",
          "webp",
          "psd",
          "svg",
          "tiff",
        ].indexOf(ext.toLowerCase()) !== -1
      );
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 发送短信
    noteSend(scope) {
      let params = {
        workOrderTypeId: scope.row.id,
      };
      let res = workOrderSend(params);
      res.then((res) => {
        Message.success(res.data);
      });
    },
    // 取消发送短信
    cancelEventNote() {
      Message.info("取消发送");
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 审批修改状态确定
    planSubmit() {
      // console.log("当前审批人id", this.forms);
      let params = {
        workOrderTypeId: this.workOrderTypeIda,
        tableId: this.tableId,
        status: this.radio,
        auditRemark: this.auditRemark,
      };
      // console.log(123562, params);
      let res = workOrderUpdateWorkUserOrderType(params);
      res.then((res) => {
        Message.success(res.data);
        this.planVisible = false;
        this.getList();
      });
    },
    // 审批进度
    planBtn(scope) {
      // this.planForm = scope.row;
      this.planVisible = true;
      this.currentRow = scope.row;
      this.workOrderTypeIda = scope.row.id;
      let params = {
        workOrderTypeId: this.workOrderTypeIda,
      };
      let res = workOrderSelectWorkUserOrderType(params);
      let res1 = workOrderSelectWorkOrderTypeCopy(params);
      res.then((a) => {
        this.planData = a.data.data;
        a.data.data.forEach((v) => {
          if (v.status == 2) {
            this.tableId = v.id;
            this.planId = v.userId;
          }
        });
      });
      res1.then((b) => {
        this.planCsrData = b.data.data;
      });
    },
    // 编辑工单确定
    orderUpdateSubmit() {
      let params = {
        id: this.updataForm.id,
        workOrderType: this.updataForm.workOrderType,
        workMessage: this.updataForm.workMessage,
      };
      let res = workOrderUpdateWorkOrderType(params);
      res.then((res) => {
        Message.success(res.data);
        this.orderUpdateVisible = false;
        this.getList();
      });
    },
    // 选择审批人的数据获取
    orderBtn() {
      // if (this.roleName != "企业") {
      //   this.parentIda = this.parentId;
      // }
      let params = {
        // parentId: this.parentIda,
      };
      let res = userList1(params);
      res.then((res) => {
        let arr = res.data.data.map((v) => {
          return { value: v.depart_id, label: v.depart_name, children: [] };
        });
        console.log("arr", arr);

        // 去重
        let map = new Map();
        for (let item of arr) {
          if (!map.has(item.value)) {
            map.set(item.value, item);
          }
        }
        let newArr = [...map.values()];
        // console.log("treeData", newArr);
        newArr.forEach((v) => {
          res.data.data.forEach((v2) => {
            if (v.value == v2.depart_id) {
              v.children.push({ value: v2.user_id, label: v2.realname });
            }
          });
        });
        // this.data = newArr;
        this.options1 = newArr;
        // console.log("tree", this.$refs.tree);
        // console.log("获得的所有数据", this.options1);
      });
    },
    // 选择审批人确定
    orderSubmit() {
      let auditUserId = this.sprValArr.map((v) => {
        return v.value;
      });
      let copyUserId = this.sprValArr1.map((v) => {
        return v.value;
      });
      let params = {
        workOrderTypeId: this.forms.id,
        auditUserId,
        copyUserId,
      };
      // console.log("hhhhhhhh", params);
      let res = workOrderAddWorkUserOrderType(params);
      res.then((res) => {
        Message.success(res.data);
        this.orderVisible = false;
        this.getList();
      });
    },
    // 删除工单
    orderDel(scope) {
      let params = {
        id: scope.row.id,
      };
      let res = workOrderDeleteWorkOrderType(params);
      res.then((res) => {
        Message.success(res.data);
        this.getList();
      });
    },
    // 标签页切换
    handleClick(e) {
      this.cutIndex = e.index;
      this.addForm = {};
    },
    // 新增工单确定--其它
    QTaddOrderSubmit() {
      let params = {
        workOrderType: this.addForm.workOrderType,
        workMessage: this.addForm.workMessage,
        workImg: this.imgUrl,
      };
      let res = workOrderAddWorkOrderType(params);
      res.then((res) => {
        Message.success(res.data);
        this.addOrderVisible = false;
        this.getList();
      });
    },
    // 新增工单确定
    addOrderSubmit() {
      this.beginTime = this.getYMDHMS(
        "YYYY-mm-dd HH:MM:SS",
        this.addForm.workorderTime[0]
      );
      this.endTime = this.getYMDHMS(
        "YYYY-mm-dd HH:MM:SS",
        this.addForm.workorderTime[1]
      );
      if (this.cutIndex == 0 || !this.cutIndex) {
        this.params = {
          workOrderType: 1,
          askType: this.addForm.askType,
          beginTime: this.beginTime,
          endTime: this.endTime,
          workMessage: this.addForm.workMessage,
          workImg: this.imgUrl,
        };
      } else if (this.cutIndex == 1) {
        this.params = {
          workOrderType: 2,
          askType: this.addForm.askType,
          beginTime: this.beginTime,
          endTime: this.endTime,
          workMessage: this.addForm.workMessage,
          workImg: this.imgUrl,
        };
      } else if (this.cutIndex == 2) {
        this.params = {
          workOrderType: 3,
          askType: this.addForm.askType,
          endTime: this.addForm.endTime,
          askTime: this.addForm.askTime,
          beginTime: this.addForm.beginTime,
          workMessage: this.addForm.BXworkMessage,
          remark: this.addForm.remark,
          workImg: this.imgUrl,
        };
      } else if (this.cutIndex == 3) {
        this.params = {
          workOrderType: 4,
          remark: this.addForm.remark,
          beginTime: this.beginTime,
          endTime: this.endTime,
          workImg: this.imgUrl,
        };
      }
      //  else if (this.cutIndex == 4) {
      //   this.params = {
      //     workOrderType: 5,
      //     workMessage: this.addForm.workMessage,
      //     askType: this.addForm.askType,
      //     endTime: this.addForm.endTime,
      //     remark: this.addForm.remark,
      //     remark1: this.addForm.remark1,
      //     askTime: this.addForm.askTime,
      //     remark2: this.addForm.remark2,
      //     remark3: this.addForm.remark3,
      //     remark4: this.addForm.remark4,
      //     beginTime: this.addForm.beginTime,
      //     workImg: this.imgUrl,
      //   };
      // }
      let res = approverAdd(this.params);
      res.then((res) => {
        // Message.success("该功能暂未开放,请在‘其它’选项中发起工单");
        Message.success(res.data);
        this.addOrderVisible = false;
        this.getList();
      });
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        userName: this.userName,
        // createDate: this.createDate,
        status: this.status,
        workOrderType: this.workOrderType,
      };
      let res = workOrderParticipantWorkOrder(params);
      res.then((res) => {
        this.pageTotal = res.data.data.total;
        this.tableData = res.data.data.records;
      });
    },
    // 清空审批人弹出框
    closeDialog() {
      this.sprValArr = [];
      this.sprValArr1 = [];
    },
    // 删除已选择审批人
    delUser(index) {
      this.sprValArr.splice(index, 1);
    },
    // 删除已选择抄送人
    delUser1(index) {
      this.sprValArr1.splice(index, 1);
    },
    // 图片上传成功时
    handleAvatarSuccess(res, file) {
      this.imgUrl = res;
      let imageUrl = URL.createObjectURL(file.raw);
      // console.log("res", res);
      // console.log("imageUrl", imageUrl);
    },
    // 添加审批人
    orderAdd() {
      let arr = this.$refs.cascaderBox.getCheckedNodes();
      this.sprValArr.push(arr[0].data);
      // 去重
      let map = new Map();
      for (let item of this.sprValArr) {
        if (!map.has(item.value)) {
          map.set(item.value, item);
        }
      }
      this.sprValArr = [...map.values()];
    },
    // 添加抄送人
    orderAdd1() {
      let arr = this.$refs.cascaderBox1.getCheckedNodes();
      this.sprValArr1.push(arr[0].data);
      // 去重
      let map = new Map();
      for (let item of this.sprValArr1) {
        if (!map.has(item.value)) {
          map.set(item.value, item);
        }
      }
      this.sprValArr1 = [...map.values()];
    },
    // 查找
    handleSearch() {
      this.getList();
    },
    // 重置
    reset() {
      this.workOrderType = null;
      this.status = null;
      this.getList();
    },
    // 审批人动态渲染
    planStatus(value) {
      if (value == 1) {
        return "待审批";
      } else if (value == 2) {
        return "审核中";
      } else if (value == 3) {
        return "审批通过";
      } else if (value == 4) {
        return "审批未通过";
      }
    },
    colorPlan(value) {
      // console.log(112233, value);
      if (value == 1) {
        return "el-icon-bottom";
      } else if (value == 2) {
        return "el-icon-bottom";
      } else if (value == 3) {
        return "el-icon-circle-check";
      } else if (value == 4) {
        return "el-icon-circle-close";
      }
    },
    planClass(value) {
      if (value == 1) {
        return "plan-status1";
      } else if (value == 2) {
        return "plan-status2";
      } else if (value == 3) {
        return "plan-status3";
      } else if (value == 4) {
        return "plan-status4";
      }
    },
    // 自定义渲染树形控件
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.append(data)}
            >
              Append
            </el-button>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.remove(node, data)}
            >
              Delete
            </el-button>
          </span>
        </span>
      );
    },
    // 树形控件的方法
    // 选中事件
    handleCheckChangeA(data, checked, indeterminate) {
      // console.log("拿到子节点数据", data, checked, indeterminate);
      // getCheckedNodes 节点可被选择时，返回所有被选中的节点
      let res = this.$refs.treeA.getCheckedNodes();
      let arr = [];
      res.forEach((item) => {
        // 只选择字节点的id
        if (!item.children) {
          arr.push(item.id);
        }
      });
      this.menuArr = this.unique(arr); // 去除重复的节点
      // this.name = menuArr.join(","); // 转字符串
      // console.log("选中节点的数据", this.menuArr);
    },
    // 数组去重
    unique(arr) {
      let newArr = [];
      let len = arr.length;
      for (let i = 0; i < len; i++) {
        if (newArr.indexOf(arr[i]) == -1) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    handleCheckChangeB(data, checked, indeterminate) {
      // console.log("拿到子节点数据", data, checked, indeterminate);
      // getCheckedNodes 节点可被选择时，返回所有被选中的节点
      let resB = this.$refs.treeB.getCheckedNodes();
      let arrB = [];
      resB.forEach((item) => {
        // 只选择字节点的id
        if (!item.children) {
          arrB.push(item.id);
        }
      });
      this.menuArrB = this.uniqueB(arrB); // 去除重复的节点
      // this.name = menuArr.join(","); // 转字符串
      // console.log("选中节点的数据", this.menuArrB);
    },
    // 数组去重
    uniqueB(arrB) {
      let newArrB = [];
      let lenB = arrB.length;
      for (let i = 0; i < lenB; i++) {
        if (newArrB.indexOf(arrB[i]) == -1) {
          newArrB.push(arrB[i]);
        }
      }
      return newArrB;
    },
    // 加载节点的方式
    loadNode(node, resolve) {
      // console.log("node", node);
      if (node.level === 0) {
        return resolve(this.data);
      }

      var hasChild;
      if (node.data.children) {
        hasChild = true;
      } else if (node.data.children) {
        hasChild = false;
      }

      // 懒加载加载效果
      // setTimeout(() => {
      var data;
      if (hasChild) {
        data = node.data.children;
      } else {
        data = [];
      }

      resolve(data);
      // }, 300);
      // console.log("data", this.data);
    },
    // 编辑工单
    orderUpdate(scope) {
      this.updataForm = scope.row;
      this.orderUpdateVisible = true;
    },
    // 选择审批人按钮
    showSelect(scope) {
      // console.log(21221233, scope.row);
      this.forms = scope.row;
      this.orderVisible = true;
    },
    // 取消删除
    cancelEvent() {
      Message.info("取消删除");
    },
    // 状态分类
    statusClass(status) {
      if (status == 0) {
        return "待审批";
      } else if (status == 1) {
        return "审核中";
      } else if (status == 2) {
        return "审核未通过";
      } else if (status == 3) {
        return "审核通过";
      }
    },
    // 状态分类字体样式
    fontClass(status) {
      if (status == 0) {
        return "status0";
      } else if (status == 1) {
        return "status1";
      } else if (status == 2) {
        return "status2";
      } else if (status == 3) {
        return "status3";
      }
    },
    // 新增工单
    addOrderBtn() {
      this.addOrderVisible = true;
      this.addForm = [];
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,.el-button--primary {
    color: #FFF;
    background-color: #3278FE;
    border-color: #3278FE;
    &:hover{
      opacity: 0.7;
    }
}
#initiateWorkorder {
  .plan-status {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    line-height: 30px;
  }
  .plan-status1 {
    background-color: #e9e9eb;
  }
  .plan-status2 {
    background-color: #b3d8ff;
  }
  .plan-status3 {
    background-color: #e1f3d8;
  }
  .plan-status4 {
    background-color: #fde2e2;
  }
  .csr {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      // margin-top: 20px;
      // flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .status0 {
      color: #909399;
    }
    .status1 {
      color: #e6a23c;
    }
    .status2 {
      color: #f56c6c;
    }
    .status3 {
      color: #67c23a;
    }
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  /deep/.el-dialog {
    width: 70%;
  }
}
</style>